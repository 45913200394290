import axios from 'axios';

const API_URL = 'https://identity.freecryptoranking.com/api/auth';
// const API_URL = 'http://localhost:3010/api/auth';

class AuthService {
  static login(user) {
    return axios.post(`${API_URL}/signin`, {
      username: user.username,
      password: user.password,
    })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return { success: true, message: 'Inicio de sesión realizado con éxito', user: response.data };
      })
      .catch(() => ({ success: false, message: 'Error al iniciar sesión' }));
  }

  static logout() {
    localStorage.removeItem('user');
  }

  static register(user) {
    return axios.post(`${API_URL}/signup`, {
      username: user.username,
      email: user.email,
      password: user.password,
    })
      .then(() => ({ success: true, message: 'Usuario registrado con éxito, consulte el correo electrónico para confirmar la cuenta' }))
      .catch((err) => {
        if (err.response.status === 400 && err.response.data.message.includes('Username')) {
          return { success: false, message: 'Nombre de usuario no disponible' };
        }
        if (err.response.status === 400 && err.response.data.message.includes('Email')) {
          return { success: false, message: 'Ya existe un usuario registrado con este email' };
        }
        return { success: false, message: 'Error al procesar registro' };
      });
  }

  static accountRecovery(email) {
    return axios.post(`${API_URL}/accountrecovery`, { email })
      .then(() => ({ success: true, message: 'Revisa el correo electrónico para recuperar tu cuenta' }))
      .catch((err) => {
        console.log(err);
        if (err.response.status === 404) {
          return { success: false, message: 'Usuario no encontrado' };
        }
        return { success: false, message: 'Error' };
      });
  }

  static changePassword(payload) {
    return axios.post(`${API_URL}/changepassword`, payload)
      .then(() => ({ success: true, message: 'Contraseña cambiada con éxito' }))
      .catch(() => ({ success: false, message: 'Error al cambiar la contraseña' }));
  }
}

export default AuthService;
