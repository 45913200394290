<template>
  <div class="ui inverted vertical footer segment">
    <div class="ui center aligned container">
      <div class="ui stackable inverted divided grid">
        <div class="three wide column">
          <h4 class="ui inverted header">Sitios Rápidos</h4>
          <div class="ui inverted link list">
            <a href="https://freebitco.in/?r=419901" target="_blank" class="item">freebitcoin</a>
            <a href="https://free-litecoin.com/login?referer=2143997" target="_blank" class="item">free-litecoin</a>
            <a href="https://www.bitgames.io/?affid=12347250" target="_blank" class="item">bitgames</a>
          </div>
        </div>
        <div class="three wide column">
          <h4 class="ui inverted header">Tarjetas para pagar con criptomonedas</h4>
          <div class="ui inverted link list">
            <a href="https://trastra.app.link/affiliate?affiliate=AF10168674" target="_blank" class="item">Trastra</a>
            <a href="https://accounts.binance.com/es/register?ref=16959657" target="_blank" class="item">Binance</a>
          </div>
        </div>
        <div class="three wide column">
          <h4 class="ui inverted header">Sitios de intercambio de criptomonedas</h4>
          <div class="ui inverted link list">
            <a href="https://accounts.binance.com/es/register?ref=16959657" target="_blank" class="item">Binance</a>
            <a href="https://accounts.binance.com/es/register?ref=16959657" target="_blank" class="item">Binance</a>
          </div>
        </div>
        <div class="seven wide column">
          <h4 class="ui inverted header">freecryptoranking</h4>
          <p>El mejor agregador de sitios para conseguir criptomonedas gratis.</p>
        </div>
      </div>
      <div class="ui inverted section divider"></div>
      <!-- <img src="assets/images/logo.png" class="ui centered mini image" /> -->
      <h4>freecryptoranking</h4>
      <div class="ui horizontal inverted small divided link list">
        <!-- <a class="item" href="#">Site Map</a>
        <a class="item" href="#">Contact Us</a>
        <a class="item" href="#">Terms and Conditions</a>
        <a class="item" href="#">Privacy Policy</a> -->
        freecryptoranking - Todos los derechos reservados.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.ui.vertical.segment:last-child {
  border-bottom: none;
}
.ui.footer.segment {
  margin: 5em 0em 0em;
  padding: 5em 0em;
}
</style>
