// import { router } from '../../main';
import AssetsService from '../services/assets.service';

export default {
  namespaced: true,
  state: {
    assets: [],
    userFavAssets: [],
  },
  getters: {
    assets(state) {
      return state.assets;
    },
    userFavAssets(state) {
      return state.userFavAssets;
    },
  },
  actions: {
    browseAssets({ commit, getters }) {
      AssetsService.browseAssets()
        .then((assets) => {
          if (getters.userFavAssets.length) {
            assets.forEach((a) => {
              const found = getters.userFavAssets.find((uf) => uf.id === a.id);
              if (found) {
                a._fav = true;
              } else {
                a._fav = false;
              }
            });
          }
          commit('browseAssets', assets);
        });
    },
    getAsset({ commit }, assetId) {
      AssetsService.getAsset(assetId)
        .then((asset) => commit('updateAsset', asset));
    },
    increaseVote({ commit }, payload) {
      const { assetId, userId } = payload;
      return AssetsService.increaseVote(assetId, userId)
        .then((result) => {
          if (result.status === 200) {
            return AssetsService.getAsset(assetId);
          }
          throw new Error();
        })
        .then((asset) => {
          commit('updateAsset', asset);
          return true;
        })
        .catch(() => false);
    },
    browseUserFavAssets({ commit }, userId) {
      return AssetsService.browseUserFavAssets(userId)
        .then((favs) => {
          const promises = favs.map((f) => AssetsService.getAsset(f.asset_id));
          return Promise.all(promises);
        })
        .then((assets) => {
          assets.forEach((a) => {
            a._fav = true;
          });
          commit('setUserFavAssets', assets);
          return assets;
        });
    },
    bookmarkAsset({ commit, getters }, paylod) {
      const { assetId, userId } = paylod;
      return AssetsService.bookmarkAsset(assetId, userId)
        .then(() => {
          const assetIndex = getters.assets.findIndex((a) => a.id === assetId);
          if (assetIndex >= 0) {
            commit('toggleFavByIndex', assetIndex);
          }
          // return AssetsService.getAsset(assetId);
        });
      /*
        .then((asset) => {
          console.log('assettttt', asset);
          asset._fav = !asset._fav;
          console.log('assettttttttttttt', asset);
          if (asset._fav) {
            commit('appendUserFav', asset);
          } else {
            commit('deleteUserFav', asset);
          }
          return asset;
        }); */
    },
  },
  mutations: {
    browseAssets(state, assets) {
      state.assets = assets;
    },
    updateAsset(state, asset) {
      const assetIndex = state.assets.findIndex((a) => a.id === asset.id);
      if (assetIndex >= 0) {
        asset._fav = state.assets[assetIndex]._fav;
        state.assets[assetIndex] = asset;
      }
    },
    setUserFavAssets(state, assets) {
      state.userFavAssets = assets;
    },
    appendUserFav(state, asset) {
      state.userFavAssets.push(asset);
      const foundAsset = state.assets.find((a) => a.id === asset.id);
      if (foundAsset) {
        foundAsset._fav = !!foundAsset._fav;
      }
    },
    deleteUserFav(state, asset) {
      const index = state.userFavAssets.findIndex((uf) => uf.id === asset.id);
      if (index >= 0) {
        state.userFavAssets.slice(index, 1);
        const foundAsset = state.assets.find((a) => a.id === asset.id);
        if (foundAsset) {
          foundAsset._fav = !!foundAsset._fav;
        }
      }
    },
    toggleFavByIndex(state, index) {
      state.assets[index]._fav = !state.assets[index]._fav;
      const ufIndex = state.userFavAssets.findIndex((uf) => uf.id === state.assets[index].id);
      if (ufIndex && state.assets[index]._fav === false) {
        state.userFavAssets.slice(ufIndex, 1);
      }
    },
  },
};
