import { createRouter, createWebHistory } from 'vue-router';
import Signup from '@/views/Signup.vue';
import Signin from '@/components/Signin.vue';
import Home from '@/views/Home.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import AccountRecovery from '@/views/AccountRecovery.vue';
import MyBookmarks from '@/views/MyBookmarks.vue';

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home,
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
//   },
// ];

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  }, {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  }, {
    path: '/signin',
    name: 'Signin',
    component: Signin,
  }, {
    path: '/resetpassword/:id/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  }, {
    path: '/accountrecovery',
    name: 'AccountRecovery',
    component: AccountRecovery,
  }, {
    path: '/mybookmarks',
    name: 'MyBookmarks',
    component: MyBookmarks,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
