<template>
  <section>
    <Form id="signup" class="ui form" @submit="handleRegister" :validation-schema="schema">
      <h4 class="ui center aligned top attached header inverted">
        Registrarte en freecryptoranking
      </h4>
      <div id="form-segment" class="ui center aligned attached segment">
        <div class="field">
          <label for="username">Nombre de usuario:</label>
          <Field id="username" name="username" type="text" placeholder="nombre de usuario" />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="field">
          <label for="email">E-mail:</label>
          <Field id="email" name="email" type="email" placeholder="email" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div class="field">
          <label for="password">Contraseña:</label>
          <Field id="password" name="password" type="password" placeholder="••••••••" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>
      </div>
      <div id="form-message" class="ui attached message">
        ¿Ya registrado? Por favor inicia sesión
        <router-link to="/signin" class="item">
          aquí
        </router-link>
      </div>
      <button class="ui bottom attached fluid button" type="submit">
        Registrarte
      </button>
      <div
        v-if="message"
        class="ui message"
        :class="successful ? 'info' : 'negative'"
      >
        {{ message }}
      </div>
    </Form>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { mapActions } from 'vuex';

export default {
  name: 'Register',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup
        .string()
        .required('Nombre de usuario es requerido!')
        .min(3, 'Debe tener al menos 3 caracteres!')
        .max(20, 'Debe tener como máximo 20 caracteres!'),
      email: yup
        .string()
        .required('Email es requerido!')
        .email('Email es inválido!')
        .max(50, 'Debe tener como máximo 50 caracteres!'),
      password: yup
        .string()
        .required('Contraseña es requerida!')
        .min(6, 'Debe tener al menos 6 caracteres!')
        .max(40, 'Debe tener como máximo 40 caracteres!'),
    });

    return {
      successful: false,
      loading: false,
      message: '',
      schema,
    };
  },
  methods: {
    ...mapActions('auth', ['register']),
    async handleRegister(user) {
      this.message = '';
      this.successful = false;
      this.loading = true;

      const response = await this.register(user);
      this.message = response.message;
      this.successful = response.success;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
section {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.error-feedback {
  color: red;
}

#signup {
  width: 450px;
  text-align: center;
}

#form-segment {
  padding-bottom: 22px;
}

#username,
#email,
#password {
  margin-top: 9px;
  text-align: center;
}
</style>
