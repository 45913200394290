import axios from 'axios';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const collectionDeserializer = new JSONAPIDeserializer({
  keyForAttribute: (key) => key,
});

const API_URL = 'https://api.freecryptoranking.com';

class AssetsService {
  static browseAssets() {
    return axios.get(`${API_URL}/assets`)
      .then((result) => collectionDeserializer.deserialize(result.data));
  }

  static getAsset(assetId) {
    return axios.get(`${API_URL}/assets/${assetId}`)
      .then((result) => collectionDeserializer.deserialize(result.data));
  }

  static increaseVote(assetId, userId) {
    return axios.post(`${API_URL}/voteup`, {
      user_id: userId,
      asset_id: assetId,
    });
  }

  static browseUserFavAssets(userId) {
    return axios.get(`${API_URL}/favourites?filter[favourites.user_id]=${userId}`)
      .then((result) => collectionDeserializer.deserialize(result.data));
  }

  static bookmarkAsset(assetId, userId) {
    const bookmark = {
      asset_id: assetId,
      user_id: userId,
    };
    return axios.post(`${API_URL}/bookmark`, bookmark)
      .then((result) => {
        if (result.status === 201) {
          return collectionDeserializer.deserialize(result.data);
        }
        return [];
      });
  }
}

export default AssetsService;
