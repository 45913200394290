<template>
  <section>
    <Form
      id="resetpassword"
      class="ui form"
      @submit="handleResetPassword"
      :validation-schema="schema"
    >
      <h4 class="ui center aligned top attached header inverted">
        Resetea tu contraseña en freecryptoranking
      </h4>
      <div id="form-segment" class="ui center aligned attached segment">
        <div class="field">
          <label for="password">Nueva contraseña:</label>
          <Field id="password" name="password" type="password" placeholder="••••••••" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>
        <div class="field">
          <label for="password-repeat">Repetir contraseña:</label>
          <Field
            id="password-repeat"
            name="password-repeat"
            type="password"
            placeholder="••••••••"
          />
          <ErrorMessage name="password-repeat" class="error-feedback" />
        </div>
      </div>
      <button class="ui bottom attached fluid button" type="submit">
        Resetear contraseña
      </button>
      <div
        v-if="message"
        class="ui message"
        :class="successful ? 'info' : 'negative'"
      >
        {{ message }}
      </div>
    </Form>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { mapActions } from 'vuex';

export default {
  name: 'PasswordReset',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      password: yup
        .string()
        .required('Contraseña es requerida!')
        .min(6, 'Debe tener al menos 6 caracteres!')
        .max(40, 'Debe tener como máximo 40 caracteres!'),
      'password-repeat': yup
        .string()
        .oneOf([yup.ref('password'), null], 'Contraseña no coincide!'),
    });

    return {
      successful: false,
      loading: false,
      message: '',
      schema,
    };
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    async handleResetPassword(passwordReset) {
      this.message = '';
      this.successful = false;
      this.loading = true;

      const payload = {
        password: passwordReset.password,
        code: this.$route.params.token,
      };
      const { success, message } = await this.changePassword(payload);
      if (success) {
        this.$router.push('/signin');
      }
      this.message = message;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
section {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.error-feedback {
  color: red;
}

#resetpassword {
  width: 450px;
  text-align: center;
}

#form-segment {
  padding-bottom: 22px;
}

#password, #password-repeat {
  margin-top: 9px;
  text-align: center;
}
</style>
