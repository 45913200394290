<template>
  <section>
    <Form
      id="accountrecovery"
      class="ui form"
      @submit="handleAccountRecovery"
      :validation-schema="schema"
    >
      <h4 class="ui center aligned top attached header inverted">
        Recupera tu cuenta
      </h4>
      <div id="form-segment" class="ui center aligned attached segment">
        <div class="field">
          <label for="email">Introduce tu correo electrónico para recuperar la cuenta:</label>
          <Field id="email" name="email" type="email" placeholder="correo electrónico" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
      </div>
      <button class="ui bottom attached fluid button" type="submit">
        Recuperar
      </button>
      <div
        v-if="message"
        class="ui message"
        :class="successful ? 'info' : 'negative'"
      >
        {{ message }}
      </div>
    </Form>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { mapActions } from 'vuex';

export default {
  name: 'AccountRecovery',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required('Email es requerido!')
        .email('Email es inválido!')
        .max(50, 'Debe tener como máximo 50 caracteres!'),
    });

    return {
      successful: false,
      loading: false,
      message: '',
      schema,
    };
  },
  methods: {
    ...mapActions('auth', ['accountRecovery']),
    async handleAccountRecovery(accountRecoveryForm) {
      this.message = '';
      this.successful = false;
      this.loading = true;
      const { message, success } = await this.accountRecovery(accountRecoveryForm.email);
      this.message = message;
      this.successful = success;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
section {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.error-feedback {
  color: red;
}

#accountrecovery {
  width: 450px;
  text-align: center;
}

#form-segment {
  padding-bottom: 22px;
}

#email {
  margin-top: 9px;
  text-align: center;
}
</style>
