import { createStore } from 'vuex';
import auth from './auth.module';
import assets from './assets.module';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    assets,
  },
  getters: {},
});
