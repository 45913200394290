<template>
  <section>
    <Form
      id="signin"
      class="ui form"
      @submit="handleSignin"
      :validation-schema="schema"
    >
      <h4 class="ui center aligned top attached header inverted">
        Iniciar sesión
      </h4>
      <div id="form-segment" class="ui center aligned attached segment">
        <div class="field">
          <label for="username">Nombre de usuario:</label>
          <Field
            id="username"
            name="username"
            type="text"
            placeholder="JohnDoe"
          />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="field">
          <label for="password">Contraseña:</label>
          <Field
            id="password"
            name="password"
            type="password"
            placeholder="••••••••"
          />
          <ErrorMessage name="password" class="error-feedback" />
        </div>
      </div>
      <div id="form-message" class="ui attached message">
        <router-link to="/accountrecovery" class="item">
          ¿Has olvidado la contraseña?
        </router-link>
      </div>
      <button class="ui bottom attached fluid button" type="submit">
        Entrar
      </button>
      <div
        v-if="message"
        class="ui message"
        :class="successful ? 'info' : 'negative'"
      >
        {{ message }}
      </div>
    </Form>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { mapActions } from 'vuex';

export default {
  name: 'Signin',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required('Username is required!'),
      password: yup.string().required('Password is required!'),
    });

    return {
      loading: false,
      message: '',
      schema,
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async handleSignin(user) {
      this.loading = true;
      const result = await this.login(user);
      if (result.success) {
        this.$router.push('/');
      }
      this.loading = false;
      this.successful = result.success;
      this.message = result.message;
    },
  },
  created() {
    if (this.$route.query.register === 'success') {
      this.successful = true;
      this.message = 'Usuario registrado con éxito, ya puedes iniciar sesión';
    } else if (this.$route.query.register === 'failure') {
      this.successful = false;
      this.message = 'Error al registrar usuario';
    }
  },
};
</script>

<style scoped>

section {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.error-feedback {
  color: red;
}

#signin {
  width: 450px;
  text-align: center;
}
</style>
