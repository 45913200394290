<template>
  <div>
    <AppHeader></AppHeader>
    <div class="ui main container">
      <router-view></router-view>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style scoped>
.main.container {
  margin-top: 7em;
}
</style>
