<template>
  <section>
    <div class="ui divided items">
      <Asset :asset="asset" v-for="asset in assets" :key="asset.id" />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Asset from '@/components/Asset.vue';

export default {
  name: 'Home',
  components: {
    Asset,
  },
  computed: {
    ...mapGetters('assets', ['assets']),
    ...mapGetters('auth', ['getUser']),
  },
  methods: mapActions('assets', ['browseAssets', 'browseUserFavAssets']),
  async created() {
    if (this.getUser) {
      await this.browseUserFavAssets(this.getUser.id);
    }
    this.browseAssets();
  },
};
</script>
