<template>
  <div class="item">
    <div class="image">
      <a :href="asset.url" target="_blank">
        <img
          :src="asset.img"
          height="160"
          :alt="asset.name"
          :title="asset.name"
        />
      </a>
      <section class="counter-container">
        <div class="ui left labeled button large" tabindex="0">
          <label class="ui basic label">
            {{ asset.votesCounter }}
          </label>
          <div
            v-bind:class="voteButtonClass"
            @click="voteUp"
          >
            <i class="plus icon"></i>
          </div>
        </div>
      </section>
    </div>
    <div class="content">
      <div class="ui right floated" @click="bookmark">
        <i class="bookmark icon big" :class="this.bookmarked"></i>
      </div>
      <a class="header" :href="asset.url" target="_blank">{{ asset.name }}</a>
      <div class="meta">
        <span v-for="(reward, index) in asset.rewards" :key="index">
          {{ reward.amount }} {{ reward.unit }}, {{ reward.description }}<br>
        </span>
      </div>
      <div class="description" v-html="asset.description">
      </div>
      <div class="extra">
        <div class="ui left floated" v-for="reward in asset.rewards" :key="reward.icon">
          <img v-if="reward.icon" :src="reward.icon" width="32"/>
        </div>
        <div class="ui label" v-for="tag in asset.tags" :key="tag">
          {{ tag }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    asset: null,
  },
  data() {
    const voteButtonClass = {
      ui: 'ui',
      icon: 'icon',
      button: 'button',
    };
    return {
      voteButtonClass,
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    bookmarked() {
      return this.asset._fav ? 'outlined' : 'outline';
    },
  },
  methods: {
    ...mapActions('assets', ['bookmarkAsset', 'increaseVote']),
    async voteUp() {
      const user = this.getUser;
      if (user) {
        const payload = {
          userId: user.id,
          assetId: this.asset.id,
        };
        this.increaseVote(payload);
      }
    },
    async bookmark() {
      const user = this.getUser;
      if (user) {
        const payload = {
          assetId: this.asset.id,
          userId: user.id,
        };
        this.bookmarkAsset(payload);
      }
    },
  },
};
</script>

<style scoped>
.counter-container {
  margin-top: 1em;
  text-align: center;
}

.bookmark {
  cursor: pointer;
}
.bookmark:hover {
  cursor: pointer;
  color: grey;
}

.ui.items>.item>.content>.header:not(.ui) {
  font-size: 1.8em;
}
</style>
