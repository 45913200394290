<template>
  <div class="ui fixed inverted menu">
    <div class="ui container">
      <router-link to="/" class="active item"> freecryptoranking </router-link>
      <div class="right menu">
        <div v-if="isLoggedIn" class="horizontal">
          <router-link to="/mybookmarks" class="item">MyBookmarks</router-link>
          <a class="item" @click="logout">Logout</a>
        </div>

        <div v-else class="horizontal">
          <router-link to="/signin" class="item">Entrar</router-link>
          <router-link to="/signup" class="item">Registrarte</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  computed: mapGetters('auth', ['isLoggedIn']),
  methods: mapActions('auth', ['login', 'logout']),
};
</script>

<style scoped>
.horizontal {
  display: flex;
  flex-direction: row;
}
</style>
