<template>
  <section>
    <div v-if="userFavAssets.length" class="ui divided items">
      <Asset :asset="asset" v-for="asset in userFavAssets" :key="asset.id" />
    </div>
    <div v-else>
      <h2>Tu lista de favoritos esta vacía.</h2>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Asset from '@/components/Asset.vue';

export default {
  name: 'MyBookmarks',
  components: {
    Asset,
  },
  computed: {
    ...mapGetters('assets', ['userFavAssets']),
    ...mapGetters('auth', ['getUser']),
  },
  methods: mapActions('assets', ['browseUserFavAssets']),
  async created() {
    if (this.getUser) {
      await this.browseUserFavAssets(this.getUser.id);
    } else {
      this.$router.push('/signin');
    }
  },
};
</script>
