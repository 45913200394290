import AuthService from '../services/auth.service';

/* eslint-disable no-shadow */

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export default {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user)
        .then(({ success, message, user }) => {
          if (success) {
            commit('loginSuccess', user);
            return { success, message };
          }
          commit('loginFailure');
          return { success, message };
        });
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user)
        .then((response) => {
          if (response.success) {
            commit('registerSuccess');
            return response;
          }
          commit('registerFailure');
          return response;
        });
    },
    changePassword({ commit }, payload) {
      return AuthService.changePassword(payload)
        .then((response) => {
          if (response.success) {
            commit('registerSuccess');
            return response;
          }
          commit('registerFailure');
          return response;
        });
    },
    accountRecovery(_, email) {
      return AuthService.accountRecovery(email);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      this.state.assets.userFavAssets = [];
      this.state.assets.assets.forEach((a) => {
        // eslint-disable-next-line
        a._fav = false;
      });
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  getters: {
    isLoggedIn: (state) => state.status.loggedIn,
    getUser: (state) => state.user,
  },
};
